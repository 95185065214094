import { useEffect, useState, useRef } from "react"

const { isMobile } = require("mobile-device-detect")

const useGeolocation = (options = {}) => {
  const updatePosition = () => {
    if (isMobile) {
      navigator.geolocation.getCurrentPosition(
        onEvent,
        onEventError,
        optionsRef.current
      )
    }
  }
  const [state, setState] = useState({
    loading: true,
    accuracy: null,
    altitude: null,
    altitudeAccuracy: null,
    heading: null,
    latitude: null,
    longitude: null,
    speed: null,
    timestamp: Date.now(),
    updatePosition,
  })

  const optionsRef = useRef(options)
  const watchId = useRef(options)

  const onEvent = ({ coords, timestamp }) => {
    setState({
      loading: false,
      accuracy: coords.accuracy,
      altitude: coords.altitude,
      altitudeAccuracy: coords.altitudeAccuracy,
      heading: coords.heading,
      latitude: coords.latitude,
      longitude: coords.longitude,
      speed: coords.speed,
      timestamp: timestamp,
      updatePosition,
    })
  }

  const onEventError = (error) => {
    setState((oldState) => ({
      ...oldState,
      loading: false,
      error,
    }))
  }
  useEffect(() => {
    if (isMobile) {
      watchId.current = navigator.geolocation.watchPosition(
        onEvent,
        onEventError,
        optionsRef.current
      )
    }
    return () => {
      navigator.geolocation.clearWatch(watchId.current)
    }
  }, [])

  return state
}
export default useGeolocation
