import React, { useRef, useState, useEffect } from "react"
import { Box, Image, Button, Flex, Text } from "rebass/styled-components"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import LazyLoad from "react-lazyload"

import Block from "./Block"
import IconPlan from "../icons/IconPlan"
import ModalCustom from "../Modal"
import ModalPhotos from "./ModalPhotos"
import { getValue, renameImage, isMembreGratuit } from "../../../utils"

const formatTitle = (photo) => {
  photo = photo || false
  return photo ? photo?.alt : ""
}
const Photos = ({ node, isMap }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [nav1, setNav1] = useState(null)
  const [nav2, setNav2] = useState(null)
  const [modalTitle, setModalTitle] = useState("")
  const medias = getValue(node, "field_co_gallery", false, []).map(
    (photo, index) => {
      return {
        uri: getValue(photo, "relationships", "field_media_image.uri.url", ""),
        alt: getValue(
          photo,
          "field_media_image",
          "alt",
          getValue(photo, "field_media_image", "title", "")
        ),
        title: getValue(
          photo,
          "field_media_image",
          "alt",
          getValue(photo, "field_media_image", "title", "")
        ),
      }
    }
  )
  const slider = useRef()
  const slider2 = useRef()

  useEffect(() => {
    if (nav1 === null && slider !== null) {
      setNav1(slider.current)
    }
    if (nav2 === null && slider2 !== null) {
      setNav2(slider2.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slider, slider2])
  if (medias.length === 0 || isMembreGratuit(node)) {
    return <></>
  }
  if (medias.length === 1) {
    medias.push(medias[0])
  }
  const settings = {
    dots: false,
    autoplaySpeed: 2000,
    arrows: false,
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: medias.length > 4 ? 4 : medias.length,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: medias.length > 3 ? 3 : medias.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  const next = () => slider.current.slickNext()
  const previous = () => slider.current.slickPrev()
  return (
    <Block title={"Photos de " + node.title}>
      <Box overflow="hidden">
        <Box mx={[-2, -2, -3]}>
          <LazyLoad overflow={isMap}>
            <Slider ref={slider} {...settings}>
              {medias.map((photo, index) => (
                <Box
                  maxHeight={["", "", "150px"]}
                  px={[2, 2, 3]}
                  key={"photo-" + index}
                >
                  <Button
                    variant="ninja"
                    onClick={() => {
                      setModalTitle(formatTitle(photo))
                      setIsOpen(index)
                    }}
                    width="100%"
                    sx={{
                      position: "relative",
                      ".hover": { opacity: 0, transition: "300ms all ease" },
                      "&:hover .hover": {
                        opacity: 1,
                      },
                    }}
                  >
                    <Flex
                      className="hover"
                      color="white"
                      flexWrap="wrap"
                      alignItems="center"
                      flexDirection="column"
                      justifyContent="center"
                      bg="rgba(47, 72, 88,0.8)"
                      css={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                      }}
                    >
                      <IconPlan icon="Loupe" size="32" />
                      <Text
                        css={{
                          position: "absolute",
                          left: 0,
                          right: 0,
                          bottom: 0,
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                        pb={2}
                        px={1}
                      >
                        {photo.alt}
                      </Text>
                    </Flex>
                    <Image
                      m="0 auto"
                      title={photo.alt}
                      alt={photo.alt}
                      maxHeight={["", "", "150px"]}
                      src={renameImage(photo.uri)}
                    />
                  </Button>
                </Box>
              ))}
            </Slider>
          </LazyLoad>
        </Box>
      </Box>
      <Flex justifyContent="flex-end" pt={4}>
        <Button
          variant="primary"
          onClick={previous}
          mr={4}
          p={2}
          aria-label="Précedent"
        >
          <IconPlan icon="arrow_left" size={24} />
        </Button>
        <Button variant="primary" onClick={next} p={2} aria-label="Suivant">
          <IconPlan icon="arrow_right" size={24} />
        </Button>
      </Flex>
      <ModalCustom
        isOpen={isOpen !== false}
        title={modalTitle}
        setIsOpen={setIsOpen}
      >
        <ModalPhotos
          images={medias}
          slideIndex={isOpen}
          onTitleChange={setModalTitle}
          node={node}
        />
      </ModalCustom>
    </Block>
  )
}
export default Photos
