import React, { useState, useEffect } from "react"
import Form from "../Form"
import Loader from "../UI/Loader"
import Message from "../UI/Message"
import { getErrorMessage } from "../../utils"

import { connect } from "react-redux"
import {
  reset_password,
  removeCurrentUser,
} from "../../redux/actions/currentUserActions"
const ResetPassword = ({ dispatch }) => {
  useEffect(() => {
    dispatch(removeCurrentUser())
  }, [])
  const [currentFormValue, setCurrentFormValue] = useState({})
  const [loading, setLoading] = useState(false)
  const [messages, setMessages] = useState([])
  const formDatareset = {
    formId: "resetpassword",
    successMessage:
      "Les instructions détaillées de connexion ont été envoyées à votre adresseee électronique.",
    submitLabel: "Demander un nouveau mot de passe",
    elements: [
      {
        type: "text",
        imputType: "email",
        name: "mail",
        default: currentFormValue.mail ? currentFormValue.mail : "",
        label: "Adresse email",
        required: true,
      },
      {
        type: "submit",
        label: "Demander un nouveau mot de passe",
        props: {
          width: 1,
        },
        buttonProps: {
          width: [1, 1, 1 / 2],
          variant: "primaryGradiant",
        },
      },
    ],
  }
  const onSubmitReset = (formValues, data, submitSuccess) => {
    setCurrentFormValue({ ...formValues })
    setLoading(true)
    setMessages([])
    dispatch(reset_password(formValues)).then((action) => {
      setLoading(false)
      if (action.type === "USER_RESET_PASSWORD_SUCCESS") {
        setMessages([
          {
            status: "success",
            value:
              "Si un compte est associé à cette adresse e-mail, alors les instructions détaillées de connexion y sont envoyées.",
          },
        ])
      }
      if (action.type === "USER_RESET_PASSWORD_FAIL") {
        const errorMessage = getErrorMessage(action.error.response)
        setMessages([{ status: "error", value: errorMessage }])
        if (
          errorMessage.includes("Session expirée, veuillez vous reconnecter.")
        ) {
          console.log("Session expirée, veuillez vous reconnecter.")
          dispatch(removeCurrentUser())
        }
      }
    })
  }
  return (
    <>
      {loading && <Loader message="Veuillez patienter." />}
      {[...messages].map((message, key) => (
        <Message
          key={message.value}
          status={message.status}
          mb={2}
          onClose={() =>
            setMessages([...messages.filter((value, index) => index !== key)])
          }
        >
          {message.value}
        </Message>
      ))}
      {!loading && (
        <Form
          data={formDatareset}
          onSubmit={(formValues, data, submitSuccess) => {
            onSubmitReset(formValues, data, submitSuccess)
          }}
        />
      )}
    </>
  )
}

export default connect()(ResetPassword)
