import React, { useState, useEffect } from "react"
import { Flex, Text, Button } from "rebass/styled-components"
import {
  getValue,
  hasRdv,
  sendEvent,
  getValidUrl,
  getOffre,
  isMembreGratuit,
} from "../../../utils"
import Rdv from "../../Rdv"
import IconPlan from "../icons/IconPlan"
import ModalCustom from "../Modal"
import { connect } from "react-redux"
import FavorisButton from "./FavorisButton"
import ShareButton from "./ShareButton"

const ButtonSecondary = ({ url, isMap, children, icon, title, ...props }) => (
  <Flex px={1} width={isMap ? [1 / 6] : [1 / 6, "auto", "auto", 1]}>
    <Button
      variant="topAction"
      as="div"
      p={2}
      px={isMap ? [2, 3] : [2]}
      mt={isMap ? 0 : [0, 0, 0, 2]}
      display="flex"
      width={1}
      justifyContent={isMap ? "center" : ["center", "center", "flex-start"]}
    >
      <a
        rel="noreferrer"
        href={url}
        style={{ display: "flex" }}
        title={title}
        {...props}
      >
        <Flex alignItems="center">
          <IconPlan icon={icon} color="primary" size="24" />
          {!isMap && (
            <Text pl={2} display={["none", "none", "block"]}>
              {children}
            </Text>
          )}
        </Flex>
      </a>
    </Button>
  </Flex>
)

const Actions = ({ node, isMap, location, isMobile }) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (location.hash === "#rdv") {
      setIsOpen(true)
      return
    }
  }, [location])

  const colorOffre = getOffre(node)
  const lien = getValidUrl(getValue(node, "field_website", "0.uri", false))

  const mail = getValue(node, "field_email")
  const standard = getValue(node, "field_phone", "Phone")
  const portable = getValue(node, "field_phone_mob", "Phone")
  const phone = standard || portable

  return (
    <Flex
      flexDirection={isMap ? "row" : ["row", "row", "row", "column"]}
      justifyContent="flex-start"
      mx={-1}
    >
      {hasRdv(node) && (
        <Flex px={1} width={isMap ? [1 / 2, 1 / 4] : [1 / 2, 1 / 3, 1 / 4, 1]}>
          <Button
            variant={"mainAction" + colorOffre}
            as="div"
            width={1}
            onClick={() => setIsOpen(true)}
          >
            <Flex
              alignItems="center"
              justifyContent="flex-start"
              flexDirection={isMap ? "row" : ["row", "row", "row", "column"]}
            >
              <IconPlan icon="calendar_outline" size="24" />
              <Text pl={2} pt={isMap ? 0 : [0, 0, 0, 2]} textAlign="left">
                Prendre RDV
              </Text>
            </Flex>
          </Button>
        </Flex>
      )}
      <FavorisButton
        node={node}
        isMap={isMap}
        width={isMap ? [1 / 6] : [1 / 6, "auto", "auto", 1]}
        displayLabel={isMap ? "none" : ["none", "none", "block"]}
      />
      <ShareButton
        isMobile={isMobile}
        node={node}
        isMap={isMap}
        width={isMap ? [1 / 6] : [1 / 6, "auto", "auto", 1]}
        displayLabel={isMap ? "none" : ["none", "none", "block"]}
      />
      {phone && (
        <ButtonSecondary
          url={phone.link}
          icon="Phone"
          isMap={isMap}
          title="Appeler"
          onClick={() =>
            sendEvent("phone", "clic-phone", node.drupal_internal__nid, 1)
          }
        >
          Appeler
        </ButtonSecondary>
      )}

      {!isMembreGratuit(node) && (
        <>
          {lien && (
            <ButtonSecondary
              url={lien}
              icon="lien-url-link"
              target="_blank"
              isMap={isMap}
              title="Visiter le site"
              onClick={() =>
                sendEvent("site", "clic-site", node.drupal_internal__nid, 1)
              }
            >
              Visiter le site
            </ButtonSecondary>
          )}
          {mail && (
            <ButtonSecondary
              url={"mailto:" + mail}
              icon="Email"
              isMap={isMap}
              title="Envoyer un email"
            >
              Envoyer un email
            </ButtonSecondary>
          )}
          <ModalCustom isOpen={isOpen !== false} setIsOpen={setIsOpen}>
            <Rdv
              nid={node.drupal_internal__nid}
              baseNode={node}
              setIsOpen={setIsOpen}
            />
          </ModalCustom>
        </>
      )}
    </Flex>
  )
}

const mapStateToProps = (state) => ({
  location: state.global.location,
  isMobile: state.global.isMobile,
})
export default connect(mapStateToProps)(Actions)
