import React from "react"
import { Box, Flex, Link } from "rebass/styled-components"
import {
  getValue,
  getValidUrl,
  sendEvent,
  isMembreGratuit,
} from "../../../utils"

const Socials = ({ node, id, ...props }) => {
  const facebook = getValue(node, "field_facebook", "uri", false)
  const instagram = getValue(node, "field_instagram", "uri", false)
  const youtube = getValue(node, "field_youtube", "uri", false)
  const linkedin = getValue(node, "field_linkedin", "uri", false)
  const tiktok = getValue(node, "field_tiktok", "uri", false)
  if (
    isMembreGratuit(node) ||
    (!instagram && !facebook && !linkedin && !youtube)
  ) {
    return <></>
  }
  return (
    <Box pt={2} {...props}>
      <Flex>
        {facebook && (
          <Box pr={2}>
            <Link
              variant="linktext"
              href={getValidUrl(facebook)}
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Facebook"
              display="block"
              onClick={() => {
                sendEvent(
                  "facebook",
                  "clic-facebook",
                  node.drupal_internal__nid,
                  1
                )
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.0"
                width="40"
                height="40"
                viewBox="0 0 32 32"
              >
                <path
                  fill="#3B5998"
                  d="M32 30a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2h28a2 2 0 012 2v28z"
                />
                <path
                  fill="#FFF"
                  d="M22 32V20h4l1-5h-5v-2c0-2 1.002-3 3-3h2V5h-4c-3.675 0-6 2.881-6 7v3h-4v5h4v12h5z"
                />
              </svg>
            </Link>
          </Box>
        )}
        {instagram && (
          <Box pr={2}>
            <Link
              variant="linktext"
              href={getValidUrl(instagram)}
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Instagram"
              display="block"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
              >
                <defs>
                  <radialGradient
                    id={"instagram-a" + id}
                    cx="4.221%"
                    cy="106.405%"
                    r="132.678%"
                    fx="4.221%"
                    fy="106.405%"
                  >
                    <stop offset="0%" stopColor="#F7E000" />
                    <stop offset="4%" stopColor="#F7C800" />
                    <stop offset="10%" stopColor="#F6B000" />
                    <stop offset="13%" stopColor="#F6A700" />
                    <stop offset="32%" stopColor="#E66149" />
                    <stop offset="36%" stopColor="#E4544D" />
                    <stop offset="43%" stopColor="#DE3159" />
                    <stop offset="50%" stopColor="#D70568" />
                    <stop offset="66%" stopColor="#BC0090" />
                    <stop offset="76%" stopColor="#A113A5" />
                    <stop offset="87%" stopColor="#8528BC" />
                    <stop offset="100%" stopColor="#5456DA" />
                  </radialGradient>
                </defs>
                <g fill="none">
                  <rect
                    width="40"
                    height="40"
                    fill={"url(#instagram-a" + id + ")"}
                    rx="1.94"
                  />
                  <g fill="#FFF" transform="translate(5.75 5.75)">
                    <path d="M19.0325,1.5525 L8.97,1.5525 C4.87997422,1.56826135 1.56826135,4.87997422 1.5525,8.97 L1.5525,19.0325 C1.56826135,23.1225258 4.87997422,26.4342386 8.97,26.45 L19.0325,26.45 C23.1225258,26.4342386 26.4342386,23.1225258 26.45,19.0325 L26.45,8.97 C26.4342386,4.87997422 23.1225258,1.56826135 19.0325,1.5525 Z M23.94875,19.0325 C23.9330473,21.741139 21.741139,23.9330473 19.0325,23.94875 L8.97,23.94875 C6.26136101,23.9330473 4.06945265,21.741139 4.05375,19.0325 L4.05375,8.97 C4.06945265,6.26136101 6.26136101,4.06945265 8.97,4.05375 L19.0325,4.05375 C21.741139,4.06945265 23.9330473,6.26136101 23.94875,8.97 L23.94875,19.0325 Z" />
                    <path d="M14.00125 7.56125C10.4445362 7.56125 7.56125 10.4445362 7.56125 14.00125 7.56125 17.5579638 10.4445362 20.44125 14.00125 20.44125 17.5579638 20.44125 20.44125 17.5579638 20.44125 14.00125 20.4255062 10.451078 17.551422 7.57699379 14.00125 7.56125L14.00125 7.56125zM14.00125 17.94C11.8259384 17.94 10.0625 16.1765616 10.0625 14.00125 10.0625 11.8259384 11.8259384 10.0625 14.00125 10.0625 16.1765616 10.0625 17.94 11.8259384 17.94 14.00125 17.94 16.1765616 16.1765616 17.94 14.00125 17.94zM20.44125 6.06625C19.5838279 6.06625 18.88875 6.76132793 18.88875 7.61875 18.88875 8.47617207 19.5838279 9.17125 20.44125 9.17125 21.2986721 9.17125 21.99375 8.47617207 21.99375 7.61875 21.99375 6.76132793 21.2986721 6.06625 20.44125 6.06625z" />
                  </g>
                </g>
              </svg>
            </Link>
          </Box>
        )}
        {youtube && (
          <Box pr={2}>
            <Link
              variant="linktext"
              href={getValidUrl(youtube)}
              rel="noopener noreferrer"
              aria-label="Youtube"
              target="_blank"
              display="block"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
              >
                <path
                  fill="red"
                  d="M2,0 L38,0 C39.1045695,-2.02906125e-16 40,0.8954305 40,2 L40,38 C40,39.1045695 39.1045695,40 38,40 L2,40 C0.8954305,40 1.3527075e-16,39.1045695 0,38 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 Z M20.5,11.6666667 L19.5,11.6666667 L17.6666667,11.6833333 C15.2,11.7333333 11.5,11.85 10.2333333,12.1666667 C9.15,12.4333333 8.31666667,13.25 8.01666667,14.2666667 C7.71666667,15.3333333 7.6,17.1 7.53333333,18.3666667 L7.5,19.7333333 L7.5,20.9833333 C7.55,22.25 7.66666667,24.4666667 8.01666667,25.7333333 C8.31666667,26.75 9.15,27.5666667 10.2333333,27.8333333 C11.4666667,28.15 15.0166667,28.2666667 17.4666667,28.3166667 L19.8166667,28.3333333 L21.75,28.3333333 C24.1666667,28.2833333 28.4166667,28.1833333 29.7666667,27.8333333 C30.8262968,27.5770952 31.6702337,26.7775759 31.9833333,25.7333333 C32.3166667,24.4833333 32.45,22.3166667 32.4833333,21.0333333 L32.4833333,19.4833333 C32.4833333,18.3666667 32.3833333,15.7166667 31.9833333,14.2666667 C31.6702337,13.2224241 30.8262968,12.4229048 29.7666667,12.1666667 C27.6258574,11.8492237 25.4642062,11.6932283 23.3,11.7 L20.5,11.6666667 Z M17.4333333,16.5 L23.9833333,20 L17.45,23.5166667 L17.45,16.4833333 L17.4333333,16.5 Z"
                />
              </svg>
            </Link>
          </Box>
        )}
        {linkedin && (
          <Box pr={2}>
            <Link
              variant="linktext"
              href={getValidUrl(linkedin)}
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Linkedin"
              display="block"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 24 24"
                fill="#0072b1"
              >
                <path d="M22.23 0H1.77C.8 0 0 .77 0 1.72v20.56C0 23.23.8 24 1.77 24h20.46c.98 0 1.77-.77 1.77-1.72V1.72C24 .77 23.2 0 22.23 0zM7.27 20.1H3.65V9.24h3.62V20.1zM5.47 7.76h-.03c-1.22 0-2-.83-2-1.87 0-1.06.8-1.87 2.05-1.87 1.24 0 2 .8 2.02 1.87 0 1.04-.78 1.87-2.05 1.87zM20.34 20.1h-3.63v-5.8c0-1.45-.52-2.45-1.83-2.45-1 0-1.6.67-1.87 1.32-.1.23-.11.55-.11.88v6.05H9.28s.05-9.82 0-10.84h3.63v1.54a3.6 3.6 0 0 1 3.26-1.8c2.39 0 4.18 1.56 4.18 4.89v6.21z" />
              </svg>
            </Link>
          </Box>
        )}
        {tiktok && (
          <Box pr={2}>
            <Link
              variant="linktext"
              href={getValidUrl(tiktok)}
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Tiktok"
              display="block"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 48 48"
              >
                <g
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#000"
                  fillRule="evenodd"
                >
                  <rect width="48" height="48" fill="#000000" />
                  <path
                    fill="#00F2EA"
                    d="M20.023 18.111v-1.703a13.17 13.17 0 0 0-1.784-.13c-7.3 0-13.239 5.94-13.239 13.24 0 4.478 2.238 8.442 5.652 10.839a13.187 13.187 0 0 1-3.555-9.014c0-7.196 5.77-13.064 12.926-13.232"
                  />
                  <path
                    fill="#00F2EA"
                    d="M20.335 37.389c3.257 0 5.914-2.591 6.035-5.82l.011-28.825h5.266a9.999 9.999 0 0 1-.17-1.825h-7.192l-.012 28.826c-.12 3.228-2.778 5.818-6.034 5.818a6.006 6.006 0 0 1-2.805-.694 6.037 6.037 0 0 0 4.901 2.52M41.484 12.528v-1.602a9.943 9.943 0 0 1-5.449-1.62 10.011 10.011 0 0 0 5.45 3.222"
                  />
                  <path
                    fill="#FF004F"
                    d="M36.035 9.305a9.962 9.962 0 0 1-2.461-6.56h-1.927a10.025 10.025 0 0 0 4.388 6.56M18.239 23.471a6.053 6.053 0 0 0-6.046 6.046 6.05 6.05 0 0 0 3.24 5.352 6.007 6.007 0 0 1-1.144-3.526 6.053 6.053 0 0 1 6.046-6.047c.623 0 1.22.103 1.784.28v-7.343a13.17 13.17 0 0 0-1.784-.13c-.105 0-.208.006-.312.008v5.64a5.944 5.944 0 0 0-1.784-.28"
                  />
                  <path
                    fill="#FF004F"
                    d="M41.484 12.528v5.59c-3.73 0-7.185-1.193-10.007-3.218v14.617c0 7.3-5.938 13.239-13.238 13.239-2.821 0-5.437-.89-7.587-2.4a13.201 13.201 0 0 0 9.683 4.225c7.3 0 13.239-5.939 13.239-13.238V16.726a17.107 17.107 0 0 0 10.007 3.218V12.75c-.72 0-1.42-.078-2.097-.223"
                  />
                  <path
                    fill="#FFF"
                    d="M31.477 29.517V14.9a17.103 17.103 0 0 0 10.007 3.218v-5.59a10.011 10.011 0 0 1-5.449-3.223 10.025 10.025 0 0 1-4.388-6.56h-5.266L26.37 31.57c-.121 3.228-2.778 5.819-6.035 5.819a6.038 6.038 0 0 1-4.901-2.52 6.05 6.05 0 0 1-3.241-5.352 6.053 6.053 0 0 1 6.046-6.046c.622 0 1.219.102 1.784.28v-5.64c-7.156.168-12.926 6.036-12.926 13.232 0 3.48 1.352 6.648 3.555 9.014a13.16 13.16 0 0 0 7.587 2.399c7.3 0 13.238-5.939 13.238-13.239"
                  />
                </g>
              </svg>
            </Link>
          </Box>
        )}
      </Flex>
    </Box>
  )
}
export default Socials
