import React from "react"
import { Box, Text, Flex, Image } from "rebass/styled-components"
import LazyLoad from "react-lazyload"
import LongText from "./LongText"
import unescape from "lodash/unescape"
import {
  getValue,
  renameImage,
  isMembreGratuit,
  isPremium,
} from "../../../utils"
import HTMLReactParser from "html-react-parser"
import Block from "./Block"
const Presentation = ({ node, isMap }) => {
  if (isMembreGratuit(node)) {
    return null
  }
  const title = unescape(node.title).replace("&#039;", "'")
  const infos_generales = getValue(node, "field_co_infos", "processed", false)

  const photo_devanture = isPremium(node)
    ? getValue(node, "field_co_cover", "field_media_image.uri.url", false)
    : false
  const slogan = getValue(node, "field_slogan", "", false)
  if ((infos_generales || photo_devanture) && !isMembreGratuit(node)) {
    return (
      <Block title={"Présentation de " + title}>
        {slogan && (
          <Text pb={2} fontWeight="bold">
            {unescape(slogan).replace("&#039;", "'")}
          </Text>
        )}
        <Flex flexDirection={["column", "column", "row"]}>
          {infos_generales && (
            <Box
              width={photo_devanture ? [1, 1, 2 / 3] : [1]}
              pr={photo_devanture ? 2 : 0}
              order={[2, 2, 0]}
            >
              <LongText text={HTMLReactParser(infos_generales)} />
            </Box>
          )}
          {photo_devanture && (
            <Box width={[1, 1, 1 / 3]} order={[0]} pb={2}>
              <LazyLoad overflow={isMap}>
                <Image
                  width="100%"
                  src={renameImage(photo_devanture) + "?vid=" + node.vid}
                  alt={"Devanture de " + title}
                />
              </LazyLoad>
            </Box>
          )}
        </Flex>
      </Block>
    )
  }
  return <></>
}
export default Presentation
