import React, { useEffect, useState } from "react"
import { Box, Text } from "rebass/styled-components"
import Loader from "../UI/Loader"
import { connect } from "react-redux"
import Form from "."
import { getValue, axiosInstance } from "../../utils"
import IconPlan from "../UI/icons/IconPlan"
const Webform = ({
  formData,
  submitButton,
  nid,
  webformId,
  user,
  token,
  onChange,
  confirmation,
}) => {
  confirmation =
    confirmation ||
    "Votre demande a bien été envoyée.\nNous vous recontacterons dans les plus brefs délais."
  formData = formData || {}
  submitButton = submitButton || {
    type: "submit",
    label: "Envoyer",
    props: {
      width: 1,
    },
    buttonProps: {
      width: [1, 1 / 2],
      variant: "primaryGradiant",
      margin: "0 auto",
      display: "block",
    },
  }
  const [sending, setSending] = useState(false)
  const [webform, setWebform] = useState(false)
  const [webformValues, setWebformValues] = useState(false)
  const [formDataState, setFormDataState] = useState({
    formId: webformId,
    useCaptcha: false,
    submitLabel: "S'inscrire",
    formUrl: `/webform_rest/submit`,
    elements: [],
    ...formData,
  })

  useEffect(() => {
    if (typeof onChange === "function") onChange(webformValues)
  }, [webformValues])

  useEffect(() => {
    var formDataTemp = {
      ...formDataState,
    }
    if (webform) {
      // formDataTemp.uuid = webform?.uuid
      Object.values(webform)
        .filter((field) => field?.["#type"])
        .forEach((comp, i) => {
          comp.type = comp["#type"] === "textfield" ? "text" : comp["#type"]
          comp.type =
            comp.type === "webform_entity_radios" ? "select" : comp.type

          comp.type = comp.type === "radios" ? "select" : comp.type
          comp.type =
            comp.type === "webform_checkboxes_other" ? "select" : comp.type

          const optionsObj =
            comp?.["#options"] || comp?.checkboxes?.["#options"] || {}

          const options = []
          if (!comp["#required"]) {
            options.push({
              value: "",
              label: "Selectionnez",
            })
          }
          Object.keys(optionsObj).forEach((key) => {
            options.push({
              value: key,
              label: optionsObj[key],
            })
          })

          const optionsSorted =
            options.length > 10
              ? options.sort((a, b) => {
                  if (a.value === "") {
                    return -1
                  }
                  return a.label.localeCompare(b.label)
                })
              : [...options]

          var def = {
            type:
              comp.type === "select" && comp["webform_multiple"]
                ? "checkboxes"
                : comp.type,
            name: comp["#webform_key"],
            placeholder: comp?.["#description"]?.["#markup"],
            default: formData?.elements?.[i]?.default || "",
            options: optionsSorted,
            label: comp["#title"],
            required: comp["#required"],
            props: {
              width: [1, 1, 1],
              ...formData?.elements?.[i]?.props,
            },
          }
          const icons = {
            adresse: "map-marker",
            geolocalisation: "ReinitNorth",
            telephone: "Phone",
            autre: "settings_outline",
          }
          if (comp["#webform_key"] === "informations_incorrectes1") {
            def.options = def.options.map((option) => {
              return {
                value: option.value,
                label: () => (
                  <>
                    <IconPlan
                      icon={icons[option.value]}
                      className="icon"
                      size="24"
                      pr="5px"
                    />
                    {option.label}
                  </>
                ),
              }
            })
          }
          formDataTemp.elements[i] = def
        })
      formDataTemp.elements[Object.values(webform).length] = submitButton

      formDataTemp.elements[Object.values(webform).length].label = webform
        ?.actions?.submit?.["#value"]
        ? webform?.actions?.submit?.["#value"]
        : submitButton.label
      setFormDataState(formDataTemp)
    }
  }, [webform, formData])
  // formData
  useEffect(() => {
    axiosInstance
      .get(`/webform_rest/${webformId}/elements`, {
        headers: {
          "X-AUTH-TOKEN": token,
        },
      })
      .then((res) => {
        setWebform(res.data)
      })
  }, [webformId])

  const onSubmit = (formValues, data, submitSuccess) => {
    if (formDataState.formUrl) {
      setSending(true)
      axiosInstance
        .post(
          formDataState.formUrl,
          {
            webform_id: webformId,
            ...formValues,
          },
          {
            headers: {
              "X-AUTH-TOKEN": token,
            },
          }
        )
        .then((res) => {
          setSending(false)
          submitSuccess()
        })
    }
  }
  return (
    <>
      {sending && (
        <Loader
          message="Envoi en cours."
          sx={{ position: "absolute", zIndex: 5, background: "#fff" }}
        />
      )}
      <Box py={2} sx={{ position: "relative", opacity: sending ? 0 : 1 }}>
        {webform === false && <Loader message=" " />}

        {getValue(webform, "body", "value", false) && webform !== false && (
          <Text>{getValue(webform, "body", "value", false)}</Text>
        )}
        {formDataState.elements.length > 0 && webform !== false && (
          <Form
            onChange={setWebformValues}
            onSubmit={onSubmit}
            data={formDataState}
            successComp={() => (
              <Box py={4} textAlign="center" width="100%">
                <svg
                  width="101"
                  height="101"
                  viewBox="0 0 101 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M50.1602 0.0500488C22.5461 0.0500488 0.160156 22.436 0.160156 50.05C0.160156 77.6657 22.5461 100.05 50.1602 100.05C77.7758 100.05 100.16 77.6657 100.16 50.05C100.16 22.436 77.7758 0.0500488 50.1602 0.0500488ZM50.1602 93.8985C26.0367 93.8985 6.41016 74.1735 6.41016 50.0499C6.41016 25.9264 26.0367 6.29985 50.1602 6.29985C74.2836 6.29985 93.9102 25.9265 93.9102 50.0499C93.9102 74.1732 74.2836 93.8985 50.1602 93.8985ZM70.1148 31.7547L40.7788 61.275L27.5679 48.0641C26.3476 46.8438 24.3694 46.8438 23.1476 48.0641C21.9272 49.2844 21.9272 51.2625 23.1476 52.4829L38.6147 67.9516C39.8351 69.1704 41.8132 69.1704 43.0351 67.9516C43.1757 67.811 43.2961 67.6578 43.4055 67.4985L74.5367 36.175C75.7555 34.9546 75.7555 32.9765 74.5367 31.7547C73.3148 30.5344 71.3367 30.5344 70.1148 31.7547Z"
                    fill="#7CAF41"
                  />
                </svg>
                <Box pt={4} textAlign="center">
                  {confirmation.split("\n").map((line, i) => (
                    <Text key={i}>{line}</Text>
                  ))}
                </Box>
              </Box>
            )}
          />
        )}
      </Box>
    </>
  )
}
const mapStateToProps = (state) => ({
  user: state.currentUser.user,
  token: state.currentUser.token,
})
export default connect(mapStateToProps)(Webform)
