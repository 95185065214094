import wkt from "wkt"
import { getValue } from "../../../utils"

export const prepareGeoJson = (markers, geojsonOnly) => {
  geojsonOnly = geojsonOnly || false
  var features = markers.filter(marker=>marker.lat!==null).map(marker => {
    var geo = {
      type: "Point",
      coordinates: marker.center,
    }
    if (geojsonOnly) {
      if (marker?.node?.type !== "company") {
        if (getValue(marker.node, "field_geofield", "geom")) {
          geo = wkt.parse(getValue(marker.node, "field_geofield", "geom"))
        }
      }
    }

    return {
      // feature for Mapbox DC
      type: "Feature",
      geometry: geo,
      properties: marker,
    }
  })
  return {
    type: "FeatureCollection",
    features: features,
  }
}
