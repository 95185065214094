import React from "react"
import { Box, Flex, Text, Link } from "rebass/styled-components"
import {
  getValue,
  renameImage,
  isPremium,
  isMembreGratuit,
} from "../../../utils"
import Block from "./Block"
import IconPlan from "../icons/IconPlan"
const Files = ({ node, isMap }) => {
if (isMembreGratuit(node)) {
		return null
	}
  const files = getValue(node, "field_co_files", false, []).map((file) => ({
    uri: getValue(file, "field_media_document", "uri.url", ""),
    filename: getValue(file, "field_media_document", "filename", ""),
  }))
  if (files.length === 0 || !isPremium(node)) {
    return <></>
  }
  return (
    <Block title={"Documents de " + node.title}>
      <Flex flexWrap="wrap" pt={2} mx={[0, 0, -2]}>
        {files.map((file, index) => (
          <Box
            width={isMap ? [1] : [1, 1, 1 / 3]}
            px={isMap ? 2 : [0, 0, 2]}
            py={2}
            key={"file-" + index}
          >
            <Link
              variant="linktextUnderline"
              width="100%"
              href={renameImage(file.uri)}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Flex alignItems="center">
                <IconPlan icon="pdf-icon" size="24" color="primary" />
                <Text
                  pl={1}
                  sx={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {file.filename}
                </Text>
              </Flex>
            </Link>
          </Box>
        ))}
      </Flex>
    </Block>
  )
}

export default Files
