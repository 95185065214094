import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Box, Flex } from "rebass/styled-components"

import MessengerBtn from "./MessengerBtn"
import Drawer from "./Drawer"
// import SearchBar from "../UI/SearchBar"
import MapResults from "./MapResults"
import Header from "./header"
import Footer from "./Footer"

const MapLayout = ({
  mapDrawerOpen,
  location,
  children,
  hasCurrent,
  mapDrawerLevel,
  crumbs,
  showCrumbs,
}) => {
  const [isMinMap, setIsMinMap] = useState(mapDrawerOpen)
  const [height, setHeight] = useState(false)
  const [onHome, setOnHome] = useState(
    location &&
      (location.pathname === "/" ||
        location.pathname === "/annuaire" ||
        location.pathname === "/annuaire/")
  )
  useEffect(() => {
    setTimeout(setIsMinMap, mapDrawerOpen ? 300 : 0, mapDrawerOpen)
  }, [mapDrawerOpen])

  useEffect(() => {
    setOnHome(location && location.pathname === "/")
  }, [location, hasCurrent])

  useEffect(() => {
    if (window) {
      setHeight(window.innerHeight)
    }
    function reportWindowSize() {
      setHeight(window.innerHeight)
    }
    function reportWindowRotate() {
      setHeight(window.innerHeight)
      setTimeout(reportWindowSizeDelay, 500)
    }
    function reportWindowSizeDelay() {
      window.scrollTo(window.scrollX, window.scrollY)
      if (window.innerWidth < window.innerHeight) {
        setHeight(window.innerHeight)
      }
    }

    // Bind the event listener
    window.addEventListener("resize", reportWindowSize)
    window.addEventListener("orientationchange", reportWindowRotate)
    return () => {
      // Unbind the event listener on clean up
      window.removeEventListener("resize", reportWindowSize)
      window.removeEventListener("orientationchange", reportWindowRotate)
    }
  }, [])
  const drawerWidth = "500px"

  return (
    <>
      <Flex
        flexDirection="column"
        width="100vw"
        height={height ? height + "px" : "100vh"}
        sx={{
          position: "absolute",
          zIndex: 3,
        }}
      >
        <Box>
          <Header />
        </Box>
        <Box
          height="100%"
          width="100%"
          sx={{
            flexGrow: 1,
            zIndex: "1",
            overflow: "hidden",
          }}
        >
          <Box
            height="100%"
            width="100%"
            sx={{
              position: "relative",
              top: "0",
              left: "0",
              zIndex: "1",
              ".map-drawer-open": {
                width: ["100%", "100%", "calc(100% - " + drawerWidth + ")"],
                right: 0,
                left: ["0", "0", drawerWidth],
                height: [
                  mapDrawerLevel > 0 ? "50%" : "100%",
                  mapDrawerLevel > 0 ? "50%" : "100%",
                  "100%",
                ],
              },
            }}
          >
            <MapResults
              className={(isMinMap ? "map-drawer-open" : "") + " " + height}
            />
            <Drawer
              drawerWidth={drawerWidth}
              onHome={onHome}
              crumbs={crumbs}
              showCrumbs={showCrumbs}
            >
              {children}
            </Drawer>
          </Box>
        </Box>
        <Footer isMap={true}/>
      </Flex>
      <MessengerBtn mb={[0, 0, "80px"]} />
    </>
  )
}

const mapStateToProps = (state) => ({
  mapDrawerOpen: state.global.mapDrawerOpen,
  location: state.global.location,
  hasCurrent: state.currentNode.hasCurrent,
  mapDrawerLevel: state.global.mapDrawerLevel,
})

export default connect(mapStateToProps)(MapLayout)
