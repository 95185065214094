import React from "react"
import { Text, Box, Button, Flex } from "rebass/styled-components"
import { connect } from "react-redux"
import { fetchSolr } from "../../../redux/actions/SolrActions"
import { navigate } from "gatsby"

const SubCats = ({
  categories,
  lastCategorieParent,
  lastCity,
  dispatch,
  word,
}) => {
  const filterCategorie = (categorie) => {
    if (word === "") {
      navigate(categorie.path.alias)
    } else {
      dispatch(
        fetchSolr(
          word,
          categorie.drupal_internal__tid,
          lastCity,
          lastCategorieParent
        )
      )
    }
  }

  return (
    <Box>
      <Flex flexWrap="wrap">
        {categories
          .filter((categorie) =>
            categorie?.relationships?.node__company
              ? categorie?.relationships?.node__company.length > 0
              : false
          )
          .map((categorie, i) => (
            <Button
              key={i}
              width={[1, 1, 1 / 2]}
              variant="ninja"
              onClick={() => filterCategorie(categorie)}
              pl={i % 2 !== 0 ? [0, 0, 2] : [0]}
              sx={
                i % 2 !== 0
                  ? {
                      borderLeftColor: "muted",
                      borderLeftWidth: ["0", "0", "1px"],
                      borderLeftStyle: "solid",
                    }
                  : {}
              }
            >
              <Flex
                alignItems="center"
                width="100%"
                px={2}
                py={2}
                ml={-1}
                pl={1}
                sx={{
                  "&:hover": {
                    backgroundColor: "grayLight",
                    borderRadius: "4px",
                  },
                }}
              >
                <Flex
                  minWidth="30px"
                  width="30px"
                  height="30px"
                  bg="primary"
                  color="white"
                  sx={{ borderRadius: "30px" }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text fontWeight="bold">
                    {categorie.relationships.node__company.length}
                  </Text>
                </Flex>
                <Text pl={2} textAlign="left">
                  {categorie.name}
                </Text>
              </Flex>
            </Button>
          ))}
      </Flex>
    </Box>
  )
}
const mapStateToProps = (state) => ({
  ...state.solr,
  location: state.global.location,
})
export default connect(mapStateToProps)(SubCats)
