import React from "react"
import { Box, Text, Flex } from "rebass/styled-components"
import { getValue, isMembreGratuit } from "../../../utils"
import HTMLReactParser from "html-react-parser"
import Block from "./Block"
const Marques = ({ node }) => {
	if (isMembreGratuit(node)) {
		return null
	}
  const marques = getValue(node, "field_brands_text", "", "").replace(/(?:\r\n|\r|\n)/g, '<br>')
  if (marques !== "") {
    return (
      <Block title={"Marques de " + node.title}>
        <Flex flexWrap="wrap">
          <Box width={[1]}>
            <Text mb={2}>{HTMLReactParser(marques)}</Text>
          </Box>
        </Flex>
      </Block>
    )
  }
  return <></>
}
export default Marques
