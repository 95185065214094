import React, { useState } from "react"
import { Box, Flex, Image, Button } from "rebass/styled-components"
import LazyLoad from "react-lazyload"

import { getValue, youTubeGetID, isMembreGratuit } from "../../../utils"

import Block from "./Block"
const Videos = ({ node, isMap }) => {
  const [videoIndex, setVideoIndex] = useState(0)
  const videos = getValue(node, "field_co_videos", false, []).map((video) => ({
    video_url: getValue(video, "field_media_oembed_video", "", ""),
  }))

  if (videos.length === 0 || isMembreGratuit(node)) {
    return <></>
  }
  return (
    <Block title={"Vidéos de " + node.title}>
      <Box overflow="hidden">
        <Box
          sx={{
            width: "100%",
            height: 0,
            paddingBottom: 900 / 16 + "%",
            position: "relative",
            overflow: "hidden",
            "& iframe": {
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              bottom: 0,
              left: 0,
              border: 0,
            },
          }}
        >
          <LazyLoad overflow={isMap}>
            <iframe
              width="560"
              height="315"
              title={node.title + " Youtube"}
              src={
                "https://www.youtube.com/embed/" +
                youTubeGetID(videos?.[videoIndex]?.video_url) +
                "?controls=1&amp;fs=0&amp;modestbranding=0&amp;showinfo=0&amp;enablejsapi=1"
              }
              frameBorder="0"
              allowFullScreen
            />
          </LazyLoad>
        </Box>
        <Flex flexWrap="wrap" pt={4} mx={-3}>
          {videos.map((video, index) => (
            <Box key={"video-" + index} width={[1 / 4]} px={3}>
              <Button
                variant="ninja"
                onClick={() => setVideoIndex(index)}
                opacity={index === videoIndex ? 1 : 0.5}
              >
                <LazyLoad overflow={isMap}>
                  <Image
                    src={
                      "https://img.youtube.com/vi/" +
                      youTubeGetID(video?.video_url) +
                      "/mqdefault.jpg"
                    }
                    alt="video thumbnail"
                  />
                </LazyLoad>
              </Button>
            </Box>
          ))}
        </Flex>
      </Box>
    </Block>
  )
}
export default Videos
