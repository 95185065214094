import React from "react"
import { Text } from "rebass/styled-components"
import { cleanString, capitalize } from "../../../utils"

const Categorie = ({ name, ...props }) => (
  <Text
    color="text"
    sx={{
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    }}
    {...props}
  >
    {capitalize(cleanString(name))}
  </Text>
)
export default Categorie
