import React, { useState } from "react"
import { getValue, convertDate } from "../../utils"
import { Box } from "rebass/styled-components"
import Form from "../Form"
import { useStaticQuery, graphql } from "gatsby"
import Loader from "../UI/Loader"
import Message from "../UI/Message"

import { connect } from "react-redux"
import {
  update,
  removeCurrentUser,
} from "../../redux/actions/currentUserActions"
import { getErrorMessage } from "../../utils"
const Modification = ({ dispatch, user }) => {
  const [messages, setMessages] = useState([])
  const [loading, setLoading] = useState(false)
  const data = useStaticQuery(graphql`
    query LesVillesModif {
      allTaxonomyTermCity(
        filter: { status: { eq: true } }
        sort: { name: ASC }
      ) {
        nodes {
          drupal_id
          drupal_internal__tid
          name
          path {
            alias
          }
        }
      }
    }
  `)

  const villeOptions = data.allTaxonomyTermCity.nodes.map(function (ville) {
    return {
      value: getValue(ville, "drupal_internal__tid"),
      label: getValue(ville, "name"),
    }
  })
  const options = [
    {
      value: "",
      label: "aucun",
    },
    ...villeOptions,
  ]
  const formData = {
    formId: "update",
    successMessage: "Votre compte a bien été mis à jour.",
    elements: [
      {
        type: "text",
        name: "field_lastname",
        default: getValue(user, "field_lastname", "0.value", ""),
        label: "Nom",
        required: true,
        props: {
          width: [1, 1, 1 / 3],
        },
      },
      {
        type: "text",
        name: "field_firstname",
        default: getValue(user, "field_firstname", "0.value", ""),
        label: "Prénom",
        required: true,
        props: {
          width: [1, 1, 1 / 3],
        },
      },
      {
        type: "textDate",
        name: "field_field_date_birth",
        default: convertDate(
          getValue(user, "field_field_date_birth", "0.value", ""),
          "DD/MM/YYYY"
        ),
        label: "Date de naissance",
        placeholder: "JJ.MM.AAAA",
        // beforeChange: value => {
        //   let pattern = /^([0-2|_][0-9|_]|(3|_)[0-1|_])(\/)(((0|_)[0-9|_])|((1|_)[0-2|_]))(\/)[1-2|_][0-9|_][0-9|_][0-9|_]$/
        //   let result = pattern.test(value)
        //   return value
        // },
        required: true,
        props: {
          width: [1, 1, 1 / 3],
        },
      },
      {
        type: "text",
        name: "mail",
        default: user.mail[0].value,
        label: "Email",
        required: true,
        props: {
          width: [1, 1, 1 / 2],
        },
      },
      {
        type: "number",
        imputType: "phone",
        name: "field_phone",
        default: getValue(user, "field_phone", "0.value", ""),
        label: "Téléphone",
        required: true,
        props: {
          width: [1, 1, 1 / 4],
        },
      },
      {
        type: "select",
        name: "field_city",
        default: getValue(user, "field_city", "0.target_id", ""),
        label: "Ville",
        required: true,
        options: options,
        props: {
          width: [1, 1, 1 / 4],
        },
      },
      {
        type: "markup",
        markup: <Box my={2} height="2px" bg="gray10" />,
        props: {
          width: 1,
        },
      },
      {
        type: "password",
        name: "current_pass",
        default: "",
        label: "Ancien mot de passe",
        props: {
          width: [1, 1, 1 / 2],
        },
        verification: function (values, data) {
          if (
            values.mail !== user.mail[0].value &&
            values.current_pass === ""
          ) {
            return "Indiquez l'ancien mot de passe pour modifier votre adresse email."
          }
          return false
        },
      },
      {
        type: "password",
        name: "pass",
        default: "",
        label: "Nouveau mot de passe",
        passwordmeter: "mail",
        props: {
          width: [1, 1, 1 / 2],
        },
        verification: function (values, data) {
          if (
            values.current_pass === values.pass &&
            values.current_pass !== ""
          ) {
            return "le nouveau mot de passe est identique a l'ancien."
          }
          if (values.pass !== "" && values.current_pass === "") {
            return "Indiquez l'ancien mot de passe pour modifier votre mot de passe."
          }
          return false
        },
      },
      {
        type: "submit",
        label: "Enregister",
        props: {
          width: 1,
          display: "flex",
          justifyContent: "center",
        },
        buttonProps: {
          width: [1, 1 / 2],
          variant: "primaryGradiant",
        },
      },
    ],
  }
  const onSubmit = (formValues) => {
    formValues.mail = [{ value: formValues.mail }]
    formValues.pass = [
      { existing: formValues.current_pass, value: formValues.pass },
    ]
    if (formValues.current_pass === "") {
      delete formValues.pass
      delete formValues.mail
    }
    delete formValues.current_pass
    formValues.field_firstname = [
      { value: formValues.field_firstname, format: null, safe_value: "" },
    ]
    formValues.field_lastname = [
      { value: formValues.field_lastname, format: null, safe_value: "" },
    ]

    formValues.field_field_date_birth = [
      {
        value:
          convertDate(
            formValues.field_field_date_birth,
            "YYYY-MM-DD",
            "DD/MM/YYYY"
          ) + "T00:00:00+11:00",
        format: null,
        safe_value: "",
      },
    ]

    formValues.field_city = [{ target_id: formValues.field_city }]
    formValues.field_phone = [
      {
        value: formValues.field_phone,
      },
    ]

    setMessages([])
    setLoading(true)
    dispatch(update(formValues, user.uid[0].value)).then((action) => {
      if (action.type === "USER_UPDATE_SUCCESS") {
        // setMessages([...messages, { status: "success", value: "Votre compte a bien été mis à jour." }]);
      }
      if (action.type === "USER_UPDATE_FAIL") {
        setLoading(false)

        const errorMessage = getErrorMessage(action.error.response)
        setMessages([...messages, { status: "error", value: errorMessage }])
        if (
          errorMessage.includes("Session expirée, veuillez vous reconnecter.")
        ) {
          console.log("Session expirée, veuillez vous reconnecter.")
          dispatch(removeCurrentUser())
        }
      }
    })
  }
  return (
    <>
      {messages.map((message, key) => (
        <Message
          key={key}
          status={message.status}
          mb={2}
          onClose={() =>
            setMessages(messages.filter((value, index) => index !== key))
          }
        >
          {message.value}
        </Message>
      ))}
      {loading && <Loader message="Modification du compte en cours" />}
      {!loading && (
        <Form
          data={formData}
          onSubmit={(formValues, data, submitSuccess) => {
            onSubmit(formValues, data, submitSuccess)
          }}
        />
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  user: state.currentUser.user,
})
export default connect(mapStateToProps)(Modification)
