import React from "react"
import { Box, Text } from "rebass/styled-components"
import moment from "moment"
import "moment/locale/fr"
import OpenLabel from "./OpenLabel"
import forEach from "lodash/forEach"
import isUndefined from "lodash/isUndefined"
import find from "lodash/find"
import filter from "lodash/filter"
import isArray from "lodash/isArray"
import sortBy from "lodash/sortBy"
import isEqual from "lodash/isEqual"
import isEmpty from "lodash/isEmpty"
import omit from "lodash/omit"
import { getValue, isMembreGratuit, capitalize } from "../../../utils"
import HTMLReactParser from "html-react-parser"

import Block from "./Block"
moment.locale("fr")

const formatHoursLine = dayHours => {
  if (!isArray(dayHours)) {
    return ""
  }
  return dayHours
    .map(({ starthours, endhours }) => {
      starthours =
        parseInt(starthours) < 10
          ? "00" + parseInt(starthours)
          : parseInt(starthours) < 100
          ? "0" + parseInt(starthours)
          : "" + starthours
      endhours =
        parseInt(endhours) < 10
          ? "00" + parseInt(endhours)
          : parseInt(endhours) < 100
          ? "0" + parseInt(endhours)
          : "" + endhours
      const startFormat =
        starthours === "005"
          ? "minuit"
          : moment(starthours, "Hmm").format("HH:mm")
      const endFormat =
        endhours === "2355" ? "minuit" : moment(endhours, "Hmm").format("HH:mm")
      return startFormat + " - " + endFormat
    })
    .join(" & ")
}

const HoursWeek = ({ hours }) => {
  var out = []
  hours = sortBy(hours, ["day"])
  forEach(hours, function(value) {
    out[value.day] = out[value.day] || []
    out[value.day].push(omit(value, ["day"]))
  })
  var days = []
  var lastDayViewed = 0
  forEach(hours, function(value, key) {
    // if (key > 0 && value.day - 1 <= lastDayViewed) {
    //   console.log([value.day - 1, lastDayViewed - 1], "new day")
    // }
    if (
      days.length &&
      isEqual(out[value.day], days[days.length - 1].hours) &&
      key > 0 &&
      value.day - 1 <= lastDayViewed
    ) {
      if (value.day !== days[days.length - 1].day) {
        days[days.length - 1].label =
          moment(days[days.length - 1].day, "E").format("dddd") +
          " au " +
          moment(value.day, "E").format("dddd")
      }
    } else {
      days.push({
        label: moment(value.day, "E").format("dddd"),
        hours: out[value.day],
        ...value,
      })
    }
    lastDayViewed =
      lastDayViewed < parseInt(value.day) ? parseInt(value.day) : lastDayViewed
  })
  return (
    <Box py={2}>
      {days.map((day, index) => (
        <Box key={"days-" + index} py={2}>
          <Text>{capitalize(day.label)}</Text>
          <Text>{formatHoursLine(day.hours)}</Text>
        </Box>
      ))}
    </Box>
  )
}

const Hours = ({ node, ...props }) => {
  if (typeof window === "undefined") {
    return <></>
  }
  if (isMembreGratuit(node)) {
    return <></>
  }

  const hoursRaw = getValue(node, "field_opentime", false,[])
  const complementHoraire = getValue(
    node,
    "field_opentime_more",
    false,
    false
  )
  const activeOpening = 1;
  if (parseInt(activeOpening) === 1) {
    if (!isUndefined(hoursRaw) && !isEmpty(hoursRaw)) {
      const hours = hoursRaw.map(function(elem) {
        elem.day = parseInt(elem.day) === 0 ? 7 : elem.day

        elem.starthours =
          parseInt(elem.starthours) < 10
            ? "00" + parseInt(elem.starthours)
            : parseInt(elem.starthours) < 100
            ? "0" + parseInt(elem.starthours)
            : "" + elem.starthours
        elem.endhours =
          parseInt(elem.endhours) < 10
            ? "00" + parseInt(elem.endhours)
            : parseInt(elem.endhours) < 100
            ? "0" + parseInt(elem.endhours)
            : "" + elem.endhours

        return elem
      })
      // const now = moment('1800', "Hmm");
      // const now = moment("2020-03-14 12:50");
      const now = moment()

      const open = find(hours, function(o) {
        return (
          parseInt(o.day) === parseInt(now.format("E")) &&
          parseInt(o.starthours) <= parseInt(now.format("Hmm")) &&
          parseInt(o.endhours) > parseInt(now.format("Hmm"))
        )
      })
      const isOpen = !isUndefined(open)
      const nextClose = isOpen
        ? now.diff(moment(open.endhours, "Hmm"), "minutes") > -60 &&
          now.diff(moment(open.endhours, "Hmm"), "minutes") < 0
          ? "Ferme dans " +
            moment(
              Math.abs(now.diff(moment(open.endhours, "Hmm"), "minutes")),
              "m"
            ).format("m") +
            " minutes"
          : false
        : false

      const nextOpeningToday = filter(hours, function(o) {
        return (
          parseInt(o.day) === parseInt(now.format("E")) &&
          parseInt(o.starthours) >= parseInt(now.format("Hmm"))
        )
      })

      const nextOpening = find(hours, function(o) {
        return (
          parseInt(o.day) > parseInt(now.format("E")) &&
          parseInt(o.starthours) >= 0
        )
      })

      const closest = !isEmpty(nextOpeningToday)
        ? nextOpeningToday.sort(
            (a, b) =>
              Math.abs(now.format("Hmm") - a.starthours) -
              Math.abs(now.format("Hmm") - b.starthours)
          )[0]
        : !isUndefined(nextOpening)
        ? nextOpening
        : find(hours, function(o) {
            return parseInt(o.day) > 0 && o.starthours >= "0000"
          })

      var closestOpenLabel = "Fermé"
      if (!isUndefined(closest)) {
        closestOpenLabel = !isEmpty(nextOpeningToday)
          ? "Ouvre " + now.to(moment(closest.starthours, "Hmm"))
          : "Ouvre " +
            (parseInt(closest.day) === parseInt(now.format("E")) + 1
              ? "Demain à " + moment(closest.starthours, "Hmm").format("H:mm")
              : moment(closest.day, "E").format("dddd") +
                " à " +
                moment(closest.starthours, "Hmm").format("H:mm"))
      }

      const hourLabel = isOpen ? "Actuellement ouvert" : closestOpenLabel

      return (
        <Block title="Horaires">
          <OpenLabel isOpen={isOpen} label={hourLabel} />
          {nextClose && <Text>{nextClose}</Text>}
          <HoursWeek hours={hours} />
          {complementHoraire && (
            <Text>
              {HTMLReactParser(
                complementHoraire.replace(/(?:\r\n|\r|\n)/g, "<br>")
              )}
            </Text>
          )}
        </Block>
      )
    } else {
      return (
        <Block title="Horaires">
          {complementHoraire && (
            <Text>
              {HTMLReactParser(
                complementHoraire.replace(/(?:\r\n|\r|\n)/g, "<br>")
              )}
            </Text>
          )}
        </Block>
      )
    }
  } else {
    const horaires = getValue(node, "field_horaire_ouverture", "value", false)
    if (horaires) {
      return (
        <Block title="Horaires">
          <Text>
            {HTMLReactParser(horaires.replace(/(?:\r\n|\r|\n)/g, "<br>"))}
          </Text>
        </Block>
      )
    }
  }
  return <></>
}
export default Hours
