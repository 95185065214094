const clusterLayer = {
  id: "clusters",
  type: "symbol",
  source: "pois",
  filter: ["has", "point_count"],
  layout: {
    "icon-allow-overlap": true,
    "icon-image": "group",
    "icon-size": 0.9,
    "icon-anchor": "top",
    "icon-offset": [0, -27],
  },
}

const clusterCountLayer = {
  id: "cluster-count",
  type: "symbol",
  source: "pois",
  filter: ["has", "point_count"],
  layout: {
    "text-field": "{point_count_abbreviated}",
    "text-size": 12,
    "text-allow-overlap": true,
  },
  paint: {
    "text-color": "#ffffff",
    "text-halo-blur": 0,
    "text-halo-color": "#ffffff",
    "text-halo-width": 0.5,
  },
}

const unclusteredPointLayer = {
  id: "unclustered-point",
  type: "symbol",
  source: "pois",
  filter: [
    "all",
    ["==", ["geometry-type"], "Point"],
    ["==", ["get", "hasPos"], true],
    ["==", ["get", "isCurrent"], false],
    // ["!", ["get", "type"], "street"],
    ["!", ["has", "point_count"]],
  ],
  layout: {
    "icon-allow-overlap": true,
    "icon-image": [
      "coalesce",
      ["image", ["get", "icon"]],
      ["image", "gratuit"],
    ],
    "icon-size": 0.9,
    "icon-anchor": "bottom",
  },
}

const currentPoiLayer = {
  id: "poi",
  type: "symbol",
  source: "current-poi",
  filter: [
    "all",
    ["==", ["geometry-type"], "Point"],
    ["==", ["get", "isCurrent"], true],
    ["==", ["get", "hasPos"], true],
    ["!", ["has", "point_count"]],
  ],
  layout: {
    "icon-allow-overlap": true,
    "icon-image": [
      "coalesce",
      ["image", ["get", "icon"]],
      ["image", "gratuit"],
    ],
    "icon-size": 0.9,
    "icon-anchor": "bottom",
  },
}

const linesLayer = {
  id: "lines",
  type: "line",
  source: "geojsons",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-width": 8,
    "line-opacity": 0.6,
    "line-color": "#3b9dcb",
  },
}

export const alllayers = [
  linesLayer,
  clusterLayer,
  clusterCountLayer,
  unclusteredPointLayer,
  currentPoiLayer,
]
