import React from "react"
import { Heading, Box, Flex } from "rebass/styled-components"

const Block = ({ title, children, ...props }) => {
  return (
    <Box py={3} {...props}>
      <Flex alignItems="center" py={3} pt="0px">
        <Heading as="h2" variant="h3">
          {title}
        </Heading>
        <Box flexGrow="1" height="2px" bg="gray10" ml={4} />
      </Flex>
      <Box>{children}</Box>
    </Box>
  )
}
export default Block
