import React from "react"
import User from "../../components/User"
import Account from "../../components/User/Account"
import Page from "../../templates/Page"
import { connect } from "react-redux"
import Loader from "../../components/UI/Loader"
import { getValue } from "../../utils"
import Seo from "../../components/layout/seo"
export const Head = (props) => {
  return <Seo {...props} title="Mon compte" />
}
const MonCompte = ({ location, loading, user }) => {
  return (
    <Page title="Mon compte" location={location} forceMap={false}>
      {!loading && (
        <>
          <User autoLoggin={true} />
          {getValue(user, "field_phone", "0.value", "") !== "" &&
            getValue(user, "field_phone_check", "0.value") && <Account />}
        </>
      )}
      {loading && <Loader message=" " />}
    </Page>
  )
}

const mapStateToProps = state => ({
  user: state.currentUser.user,
  loading: state.currentUser.loading,
})
export default connect(mapStateToProps)(MonCompte)
