import { createStore, applyMiddleware, compose } from "redux"
import rootReducer from "./reducers"
import { thunk } from "redux-thunk"
import axios from "axios"
import { multiClientMiddleware } from "redux-axios-middleware"

import { persistStore, persistReducer, createTransform } from "redux-persist"
import storage from "redux-persist/lib/storage" // defaults to localStorage for web
import { initialState } from "./reducers/currentUser"
import { apiUrl } from "../utils"
const middlewareConfig = {
  interceptors: {
    request: [
      {
        success: function ({ getState, dispatch, getSourceAction }, req) {
          // const currentUser = getState().currentUser;

          // req.headers["Content-Type"] = "application/json"
          // console.log(req);
          // if (currentUser.token && ) {
          //   req.headers['X-CSRF-Token'] = currentUser.token
          // }
          return req
        },
        // error: function({ getState, dispatch, getSourceAction }, error) {
        //   //...
        //   return error
        // }
      },
    ],
    // response: [{
    //   success: function({ getState, dispatch, getSourceAction }, res) {
    //     console.log(res); //contains information about request object
    //     //...
    //     return Promise.resolve(res);
    //   },
    //   error: function({ getState, dispatch, getSourceAction }, error) {
    //     return Promise.reject(error);
    //   }
    // }]
  },
}

const clients = {
  default: {
    client: axios.create({
      baseURL: apiUrl,
      responseType: "json",
      // withCredentials: process.env.NODE_ENV !== "development",
      crossdomain: true,
      headers: { "Content-Type": "application/json" },
    }),
    options: middlewareConfig,
  },
  DrupalRest: {
    client: axios.create({
      baseURL: apiUrl,
      responseType: "json",
      // withCredentials: process.env.NODE_ENV !== "development",
      crossdomain: true,
      headers: { "Content-Type": "application/json" },
      params: { _format: "json" },
      transformResponse: [
        (data) => {
          if (typeof data === "string") {
            data = JSON.parse(data)
          }
          return data
        },
      ],
    }),
    options: {
      interceptors: {
        request: [
          {
            success: function ({ getState, dispatch, getSourceAction }, req) {
              req.headers["X-AUTH-TOKEN"] = ""
              const currentUser = getState().currentUser
              if (currentUser.token) {
                req.headers["X-AUTH-TOKEN"] = currentUser.token
              }
              return req
            },
          },
        ],
      },
    },
  },
  // Api: {
  //   client: axios.create({
  //     baseURL: "/api",
  //     responseType: "json",
  //     // withCredentials: process.env.NODE_ENV !== "development",
  //     crossdomain: true,
  //     headers: { "Content-Type": "application/json" },
  //   }),
  // },
  // solR: {
  //   client: axios.create({
  //     baseURL:apiUrl+"solr/plannc",
  //     responseType: "json",
  //   }),
  // },
}
const customTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState) => {
    return { ...initialState, ...inboundState }
  },
  // transform state being rehydrated
  (outboundState) => {
    if (!outboundState.keeploggedin) {
      if (Math.floor(Date.now() / 1000) - outboundState.loadedAt > 60 * 60) {
        if (document && outboundState.session_name) {
          document.cookie =
            outboundState.session_name +
            "= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
        }
        return { ...initialState }
      }
    }
    // convert mySet back to a Set.
    return { ...outboundState }
  },
  // define which reducers this transform gets called for.
  { whitelist: ["currentUser"] }
)
const persistConfig = {
  key: "root",
  whitelist: ["persist", "currentUser"],
  transforms: [customTransform],
  storage,
}
const composeEnhancers =
  process.env.NODE_ENV === "development"
    ? (typeof window !== "undefined"
      ? (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            trace: true,
            traceLimit: 25,
          })
        : compose)
      : compose)
    : compose

const persistedReducer = persistReducer(persistConfig, rootReducer)
//
// export default () => {
//   let store = createStore(
//     persistedReducer,
//     composeEnhancers(applyMiddleware(thunk, multiClientMiddleware(clients))),
//   )
//   let persistor = persistStore(store)
//   return { store, persistor }
// }

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk, multiClientMiddleware(clients)))
)

const persistor = persistStore(store)

export { store, persistor }
