import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from "./store";
const ReduxWrapper = ({ children }) => {
  // if (typeof window === "undefined") {
  //   return (
  //     <Provider store={store}>
  //       {children}
  //     </Provider>
  //   )
  // }
  return (
    <Provider store={store}>
      <PersistGate loading={<>{children}</>} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  )
}

export default ReduxWrapper;
