/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import ReactGA from "react-ga"
import { getValue, renameImage, getLogo, hasRdv } from "../../utils"
function SEO({
  description,
  title,
  image,
  keywords,
  type,
  location,
  params,
  data,
}) {
  image = image || "/og-image.png"
  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      ReactGA.initialize("UA-625331-61")
      ReactGA.pageview(window.location.pathname)
    }
  }, [])

  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
          siteUrl
          keywords
        }
      }
    }
  `)

  const url = `${site.siteMetadata.siteUrl}/${location.pathname}`
  switch (type) {
    case "poi":
      const { nodeCompany: node } = data

      const titleParts = [node.title]
      const categorieName = getValue(node, "field_co_cats", "0.name", "")
      titleParts.push(categorieName)
      const villeName = getValue(node, "field_city", "name", "")
      titleParts.push(villeName)
      const code_offre = getValue(
        node,
        "field_offer",
        "name",
        "gratuit"
      ).toLowerCase()
      const descriptions = {
        desc_gratuit: `Retrouvez facilement le numéro de téléphone et l'adresse de ${node.title}, à ${villeName} sur l'annuaire Plan.nc.`,
        desc_gold: `Retrouvez les horaires et tous les services de ${node.title}, à ${villeName}. Trouvez les numéros de téléphone et adresses des entreprises sur Plan.nc.`,
        desc_premium: hasRdv(node)
          ? `Prenez RDV chez ${node.title}, à ${villeName}. ${categorieName}`
          : `Retrouvez les horaires et tous les services de ${node.title}, à ${villeName}. ${categorieName}`,
      }

      title = titleParts.join(" | ")
      description = descriptions["desc_" + code_offre]
      keywords = getValue(node, "field_co_keywords", false, "")
      image = renameImage(getLogo(node, "/og-image.png"))
      break
    case "category":
      var { taxonomyTermCat: categorie } = data
      title = categorie.name
      description =
        "Retrouvez toutes les entreprises du secteur " +
        categorie.name +
        " en Nouvelle-Calédonie sur Plan.nc."
      break

    case "categoryVille":
      var { taxonomyTermCat: categorieVille, taxonomyTermCity: ville } = data
      title = categorieVille.name + " | " + ville.name
      description =
        "Retrouvez toutes les entreprises " +
        categorieVille.name +
        " situées à " +
        ville.name +
        " sur Plan.nc. Trouvez les numéros de téléphone et adresses des entreprises de votre quartier ou de votre ville sur Plan.nc"
      break
    case "search":
      var word = params.word
      title = 'Résultats de recherche "' + word + '"'
      description = 'Recherchez "' + word + '" sur Plan.nc'
      break

    default:
      break
  }

  const metaDescription = description || site.siteMetadata.description
  const metaTitle = title || site.siteMetadata.title
  const metaKeywords = keywords || metaTitle + ", " + site.siteMetadata.keywords
  return (
    <>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta name="image" content={site.siteMetadata.siteUrl + image} />
      <meta name="keywords" content={metaKeywords} />

      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={site.siteMetadata.siteUrl + image} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content={site.siteMetadata.title} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={site.siteMetadata.siteUrl + image} />
      <link
        rel="search"
        type="application/opensearchdescription+xml"
        title={site.siteMetadata.title}
        href={
          process.env.NODE_ENV === "development"
            ? "http://localhost:8888/opensearchdev.xml"
            : "https://plan.nc/opensearch.xml"
        }
      />
    </>
  )
}

export default SEO
