import React, { useRef, useEffect, useState } from "react"
import { navigate } from "gatsby"
import { Box, Flex, Image, Text } from "rebass/styled-components"
import Lieu from "./lieu"
import unescape from "lodash/unescape"
import LazyLoad from "react-lazyload"

import { useIsVisible } from "react-is-visible"
import {
  getValue,
  renameImage,
getLogo,
  hasRdv,
  getNodePath,
  // isMembreGratuit,
  formatDistance,
  sendEvent,
} from "../../utils"
import { connect } from "react-redux"
import { setFocusedNode } from "../../redux/actions/currentNodeActions"

import Title from "../../components/UI/poi/Title.js"
// import OpenLabel from "../../components/UI/poi/OpenLabel"
import Address from "../../components/UI/poi/Address"
import SquareBox from "../../components/UI/SquareBox"
import BadgeOffreTriangle from "../../components/UI/poi/BadgeOffreTriangle"
import Categorie from "../../components/UI/poi/Categorie"
import Actions from "./Actions"

const Poi = ({ focusedItem, node, isMap, searchPos, dispatch }) => {

  const [viewed, setViewed] = useState(false)
  const nodeRef = useRef()
  const isVisible = useIsVisible(nodeRef)
  useEffect(() => {
    let timer1
    if (isVisible && node && !viewed) {
      timer1 = setTimeout(function () {
        setViewed(true)
        clearTimeout(timer1)
        sendEvent("affichage", "view-affichage", node.drupal_internal__nid, 1)
      }, 500)
    }
    if (!isVisible) {
      clearTimeout(timer1)
    }
    return () => {
      clearTimeout(timer1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])
  const url = getNodePath(node, isMap)

  const focusOn = () => {
    // navigate(url)
    // return
    if (isMap) {
      dispatch(setFocusedNode(node, true))
      if (actionVisible) {
        navigate(url)
      }
    } else {
      navigate(url)
    }
  }
  const title = unescape(node.title).replace("&#039;", "'")

  var actionVisible = true
  if (isMap) {
    actionVisible = false
    if (focusedItem) {
      actionVisible =
        focusedItem.drupal_internal__nid === node.drupal_internal__nid
    }
  }
  if (node.type !== "company") {
    return <Lieu node={node} isMap={isMap} />
  }
  // const hours = getValue(node, "field_opening_hours", "Raw", false)
  // const isGratuit = isMembreGratuit(node)
// console.log(node, "noderesult")
  return (
    <Box
      ref={nodeRef}
      pt={2}
      pr={isMap ? 2 : 0}
      css={{
        position: "relative",
      }}
    >
      <Box onClick={focusOn}>
        <BadgeOffreTriangle
          height={["20px", "20px", isMap ? "20px" : "34px"]}
          width={["20px", "20px", isMap ? "20px" : "34px"]}
          node={node}
          mr={isMap ? 2 : 0}
          mt={2}
          css={{ position: "absolute", top: 0, right: 0 }}
        />
        <Box
          pl={actionVisible && isMap ? 2 : 0}
          pb={2}
          sx={{
            position: "relative",
            cursor: "pointer",
            transition: "all 300ms ease",
            "&:hover": {
              pl: isMap ? 2 : 4,
              mr: actionVisible ? 0 : -2,
              "&:before": {
                // width: isMap ? (actionVisible ? '10px' : '5px') : '5px',
                width: actionVisible ? "10px" : "10px",
                opacity: 1,
              },
            },
            "&:before": {
              content: '""',
              position: "absolute",
              top: 0,
              height: "90px",
              opacity: actionVisible && isMap ? 1 : 0,
              bottom: 0,
              bg: "primary",
              left: 0,
              width: actionVisible && isMap ? "10px !important" : "0",
              borderTopRightRadius: 2,
              borderBottomRightRadius: 2,
              transition: "all 300ms ease",
            },
          }}
        >
          <Flex pl={isMap ? 2 : 0} pr={2} justifyContent="stretch">
            <Box minWidth={["90px"]} minHeight={["90px"]}>
              <SquareBox>
                <LazyLoad overflow={isMap}>
                  <Image
                    width="100%"
                    src={renameImage(getLogo(node)) + "?vid=" + node.vid}
                    alt={"logo de " + title}
                  />
                </LazyLoad>
              </SquareBox>
            </Box>
            <Box pl={5} width={[1]}>
              <Title fontSize="20rem !important" title={title} pr={4} />
              <Flex justifyContent="space-between" alignItems="center">
                <Box>
                  <Flex alignItems="baseline" flexDirection="row" pb={1}>
                    <Categorie
                      fontSize="14rem"
                      name={getValue(
                        node,
                        "relationships",
                        "field_co_cats.0.name",
                        ""
                      )}
                      link={false}
                    />
                  </Flex>
                  <Address
                    node={node}
                    onResult={true}
                    fontSize="14rem !important"
                    sx={{
                      ".icon-plan-map-marker": {
                        fontSize: "14rem !important",
                        width: "14rem !important",
                      },
                    }}
                  />
                </Box>

                {hasRdv(node) && (
                  <Box color="purple" maxWidth="40px">
                    <Image
                      display={["block"]}
                      opacity={actionVisible ? 0 : 1}
                      sx={{ transition: "opacity 200ms ease" }}
                      src="/rdv-picto.svg"
                      alt="Rdv disponible"
                    />
                  </Box>
                )}
              </Flex>
              {searchPos !== false && (
                <Text textAlign="right" color="primary" fontWeight="bold">
                  {formatDistance(node.distance)}
                </Text>
              )}
            </Box>
          </Flex>
        </Box>
      </Box>
      <Actions
        node={node}
        isMap={isMap}
        url={url}
        actionVisible={actionVisible}
      />
      <Box ml={actionVisible && isMap ? 2 : 0} height="1px" bg="#d8d8d8" />
    </Box>
  )
}

const mapStateToProps = (state) => ({
  focusedItem: state.currentNode.focusedItem,
  searchPos: state.solr.searchPos,
})

export default connect(mapStateToProps)(Poi)
